.Game {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #ffccff, #66ccff, #cc99ff, #ff99cc);

  background-size: 200% 200%;
  animation: gradientAnimation var(--danceSpeed) ease infinite;
  font-family: 'Architects Daughter', cursive;
}

.Game--Start, .Game--Welcome {
  justify-content: center;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

