.Challenge {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  height: 70vh;
  width: 100%;
  z-index: 1;
}
@media (max-width: 450px) {
  .Challenge {
    flex-direction: row;
    height: 75vh;
  }
}
