.Album {
  display: flex;
  padding: 10px;
  max-width: 30vw;
  flex-grow: 1;
  justify-content: center;
  width: 50vw;
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.placeholder-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black overlay */
}
.placeholder-overlay .text {
  font-size: 50px;
  color: white;
  text-align: center;
}

@media (max-width: 450px) {
  .Album {
     max-width: 60vw;
     width: 60vw;
  }

  .placeholder-overlay .text {
    font-size: 35px;
  }
}

.image {
  width: 100%;
  position: absolute;
  top: 0;
}
@media (max-width: 768px) {
  .Album {
    margin-bottom: 40px;
  }
}
.Album-art {
  width: 100%;
  padding-top: 100%;
  animation-iteration-count: infinite;
  position: relative;
  animation-duration: var(--danceSpeed);
  animation-name: var(--danceName);
  animation-timing-function: linear;
}

.Album-art--reverse{
  animation-direction: reverse;
}

@keyframes sway {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes wiggle {
  5%, 25%, 55%, 85%, 100% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(-30px);
  }
  65% {
    transform: translateY(-15px);
  }
  0% {
    transform: translateX(0) rotate(0deg);
  }
  25% {
    transform: translateX(-10px) rotate(-10deg);
  }
  50% {
    transform: translateX(0) rotate(0deg);
  }
  75% {
    transform: translateX(10px) rotate(10deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}
