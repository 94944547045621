.scoreboard {
  width: 90%;
  margin: 5%;
  padding: 10px;
  background-color: #7b68ee;
  border-radius: 5px;
  margin: 20px auto;
  text-align: center;
  height: 5vh;
  border: 4px solid #fff;
  color: white;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  border-radius: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-size: 0% 0%;
}

.scoreboard--correct {
  background: radial-gradient(circle at center, #b2fba2 0%, #5dd367 70%);
  background-size: 200% 200%;
  transition: background-size 1s ease-in-out;
}

.scoreboard--incorrect {
  background: radial-gradient(circle at center, #fba2a2 0%, #d36767 70%);
  background-size: 200% 200%;
  transition: background-size 1s ease-in-out;
}

.scoreboard--correct .score__best {
  display: none;
}

.scoreboard--correct .score__current {
  font-size: 40px
}

.scoreboard--retry .score__current {
  display: none;
}

.scoreboard--retry .score__best {
  font-size: 40px;
}

.scoreboard--winner {
  border-width: 10px;
  border-image: conic-gradient(from var(--angle), #ffb6c1, #ffffe0, #d1ffa3, #aeeeee, #add8e6, #ffb5ff) 1;	animation: 2s rotate linear infinite;
  flex-wrap: wrap;
  height: 20vh;
}

.scoreboard--winner .score {
  font-size: 40px;
}

@keyframes rotate {
	to {
		--angle: 360deg;
	}
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.score {
  display: inline-block;
  font-size: 20px;
}

@media (max-width: 390px) {
  .score {
    width: 100%;
  }
}

/* Set the color of the text inside the scoreboard */
#best-score, #current-score {
  color: #fff;
  font-weight: bold;
}
