.Selector-Item {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
@media (max-width: 390px) {
  .Selector-Item {
     width: 100vw;
     flex-direction: column;
     z-index: 1000;
  }
}

.selector__button {
  border: none;
  background: none;
  z-index: 2;
}

.selector__image{
  width: 300px;
  margin: 5px;
  z-index: 2;
}
