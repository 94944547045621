.GameControls {
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}
.GameControls img {
  flex-grow: 1;
  width: 500px;
  max-width: 80%;
  margin-bottom: 15px;
}
.NewGameButton {
  padding: 30px 75px;
  font-size: 30px;
  background-color: #ffccff;
  color: #fff;
  border: 4px solid #fff;
  border-radius: 30px;
  cursor: pointer;
  font-family: 'Architects Daughter', cursive;

  background: linear-gradient(
    135deg,
    #ffc0cb,
    #ffd700,
    #ff69b4,
    #9370db,
    #7b68ee
  );
  background-size: 100% 100%;
  animation: shimmerAnimation 2s linear infinite;

}

@keyframes shimmerAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.NewGameButton:active{
  transform: scale(0.9);
}
