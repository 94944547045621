.Welcome {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 4px solid #fff;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  border-radius: 5px;
  margin: 10px;
  text-align: center;
  z-index: 1;
}

.Welcome h1 {
  font-size: 1.5em;
  margin: 0.5em;
  padding: 0;
}

.Welcome p {
  font-size: 0.75em;
  margin: 0.4em;
}
