.Visualizer {
 width: 100%;
 position: fixed;
 bottom: 0;
 z-index: 0;
}
canvas {
  position: absolute;
  bottom: 0;
  z-index: 0;
}
