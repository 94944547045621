.PlayAgain {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 4px solid #fff;
  padding: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent black overlay */
  border-radius: 5px;
  margin: 10px;
  text-align: center;
  z-index: 1;
}
